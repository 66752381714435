<template>
  <div class="d-flex flex-row" :dir="$t('clan')=='ar'?'rtl':'ltr'">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end">
            <KTDropdown2></KTDropdown2>
          </div>
          <div class="d-flex align-items-center">
            <div
              class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
            >
              <div
                class="symbol-label mb-5"
                :style="{
                  backgroundImage: `url('https://ritecaremedicalofficepc.com/wp-content/uploads/2019/09/img_avatar.png')`,
                }"
              ></div>
              <i class="symbol-badge bg-success"></i>
            </div>
            <div>
              <a
                class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary mr-5"
                >{{ customer.name }}</a
              >
              <div class="mt-2"></div>
            </div>
          </div>
          <!--end::User-->

          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
            dir="ltr"
          >
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4 active"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="0"
                data-toggle="tab"
                role="tab"
                aria-selected="true"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <i class="fas fa-user"></i>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('Profile Information')}}</span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="1"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <i class="fas fa-map-marked-alt"></i>
                  </span>
                </span>
                <span class="navi-text font-size-lg"
                  >{{$t('Addresses Information')}}</span
                >
              </a>
            </div>

            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="2"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <i class="fas fa-shopping-cart"></i>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('Orders Information')}} </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div :class="$t('clan')=='en'?'flex-row-fluid ml-lg-8':'flex-row-fluid mr-lg-8'">
      <b-tabs class="hide-tabs" v-model="tabIndex" :no-nav-style="true">
        <b-tab active>
          <ProfileInformation :customer="customerRedirect === ''?customer: customerRedirect"></ProfileInformation>
        </b-tab>

        <b-tab>
          <AddressesInfromation :customer="customerRedirect === ''?customer: customerRedirect"></AddressesInfromation>
        </b-tab>

        <b-tab>
          <OrdersInformation
            :customer="customerRedirect === ''?customer: customerRedirect"
            
          ></OrdersInformation>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTDropdown2 from "@/view/content/dropdown/Dropdown2";

import ProfileInformation from "@/view/pages/customers/view-comp/ProfileInformation";
import AddressesInfromation from "@/view/pages/customers/view-comp/AddressesInfromation";
import OrdersInformation from "@/view/pages/customers/view-comp/OrdersInformation";

export default {
  name: "ViewCustomer",
  components: {
    KTDropdown2,
    ProfileInformation,
    AddressesInfromation,
    OrdersInformation,
  },
  data() {
    return {
      tabIndex: 0,
      customerRedirect:''
    };
  },
  computed: {
    customer() {
      return (
        this.customers.find(
          (customer) => customer.id == this.$route.params.id
        ) || {}
      );
    },
    ...mapState({
      customers: (state) => state.customers.customers,
    }),

  },
  async mounted() {
     console.log(this.$route.params)
    if(this.$route.params.customer !== null){
      this.customerRedirect = this.$route.params.customer
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Customers", route: "/admin/customers" },
      { title: "View Customer" },
    ]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
  },
};
</script>
