<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('Orders Information')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Check Orders')}}</span
        >
      </div>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->

        <OrdersTable  />
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import OrdersTable from "@/view/pages/customers/view-comp/OrdersTable.vue";

export default {
  name: "OrdersInformation",

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  components: {
    OrdersTable,
  },

  

  methods: {
    save() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch("customers/update", this.customer);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        Swal.fire({
          title: "",
          text: "Driver has been update successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }, 2000);
    },
    cancel() {},
  },
};
</script>

<style scoped>

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}
</style>