<template>
  <div>
    <b-table
      :items="items"
      :fields="$t('clan')=='en'?fields:fieldsAR"
      :responsive="true"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="md"
      show-empty
      
    >

    <template #empty>
          <div v-if="isBusy" class="text-center">
            <b-spinner
              variant="primary"
              label="Spinning"
              class="spinnerClass"
            ></b-spinner>
          </div>
          <p v-else class="text-center muted mb-0 mt-2" style="font-size: 13px">
            {{ $t("There are no records to show") }}
          </p>
        </template>


      <template #cell(created_at)="row">
        <p>{{ row.item.created_at.split(" ")[0] }}</p>
      </template>

      <template #cell(isAddressConfirmed)="row">
        <div v-if="row.item.isAddressConfirmed == '1'" >
          <b-badge variant="success" style="font-size:10px">{{$t('confirmed')}}</b-badge>
        </div>
        <div v-else>
          {{$t('No')}}
        </div>
      </template>

      <template #cell(status)="row">
        <span
          class="label font-weight-bold label-lg label-light-secondary text-dark label-inline"
          v-if="row.item.status === 'Created'"
          >{{ row.item.status }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-primary label-inline"
          v-if="row.item.status === 'Started'"
          >{{ row.item.status }}</span
        >
         <span
          class="label font-weight-bold label-lg label-light-danger label-inline"
          v-if="row.item.status === 'Failed'"
          >{{ row.item.status }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-danger label-inline"
          v-if="row.item.status === 'Cancelled'"
          >{{ row.item.status }}</span
        >
        <span
          class="label font-weight-bold label-lg label-light-success label-inline"
          v-if="row.item.status === 'Successful'"
          >{{ row.item.status }}</span
        >
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>

    <b-pagination dir="ltr"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      :align="$t('clan')=='ar'?'right':'left'"
      size="lg"
      class="my-0"
    ></b-pagination>
  </div>
</template>
<script>
export default {
  name: "OrdersTable",
  data() {
    return {
      isBusy:'',
      totalRows: 1,
      currentPage: 1,
      perPage: 7,
      items:[],
      fields: [
        {
          key: "client.name",
          label: "Client Name",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "job_id",
          label: "Job ID",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
        },
        {
          label: "Confirmed?",
          key: 'isAddressConfirmed',
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "fees",
          label: "Fees",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "amount",
          label: "Amount",
          sortable: true,
          sortDirection: "desc",
        },
      ],
      fieldsAR: [
        {
          key: "client.name",
          label: "إسم العميل",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "job_id",
          label: "Job ID",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "status",
          label: "الحالة",
          sortable: true,
          sortDirection: "desc",
        },
        {
          label: "هل تم تأكيد الموقع؟",
          key: 'isAddressConfirmed',
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "created_at",
          label: "تاريخ الإنشاء",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "fees",
          label: "الرسوم",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "amount",
          label: "المبلغ",
          sortable: true,
          sortDirection: "desc",
        },
      ],
    };
  },
  async created() {
    let url = new URL(window.location.href);
    let id = url.pathname.split('/')[3]
    //console.log()
    this.isBusy = true
    await this.$store.dispatch('orders/customerOrder' , id).then(res=>{
      console.log(res.data.data)
      this.items = res.data.data
      this.perPage = this.items.length
      this.isBusy = false
    })
  },

  methods: {
    /*view(id) {
      this.$router.push({ name: "ViewCustomer", params: { id: id } });
    },*/
  },
};
</script>
<style lang="css" scoped>

</style>