<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('Profile Information')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Change your profile settings')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <div class="row">
          <label class="col-xl-3"></label>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Customer ID')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="customer.id"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Customer Name')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="customer.name"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('phone')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="customer.phone"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Email')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="customer.email"
            />
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "ProfileInformation",

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },

  methods: {
    save() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch("customers/update", this.customer);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        Swal.fire({
          title: "",
          text: "Driver has been update successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }, 2000);
    },
    cancel() {},
  },
};
</script>


<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>