<template>
  <!--begin::Card-->
  <div>
    <div v-if="IsthereAddreses === true">
      <div
        class="card card-custom mt-5"
        v-for="ad in customer.addresses"
        :key="ad.id"
      >
        <!--begin::Header-->
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">
              {{$t('Addresses Information')}}
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1"
              >{{$t('Check address informations')}}</span
            >
          </div>
          <div class="card-toolbar"></div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
          <div class="card-body">
            <!--begin::Heading-->

            <!--begin::Form Group-->

            <div class="row">
              <div class="form-group col-sm-6">
                <label class="col-xl-6 col-lg-12 col-form-label"
                  >{{$t('Street Name')}}</label
                >
                <div class="">
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    v-model="ad.street_name"
                  />
                </div>
              </div>

              <div class="form-group col-sm-6">
                <label class="col-xl-6 col-lg-12 col-form-label">{{$t('Area')}}</label>
                <div class="">
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    v-model="ad.area"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-4">
                <label class="col-xl-6 col-lg-12 col-form-label"
                  >{{$t('Building')}}</label
                >
                <div class="">
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    v-model="ad.building"
                  />
                </div>
              </div>

              <div class="form-group col-sm-4">
                <label class="col-xl-6 col-lg-12 col-form-label">{{$t('Floor')}}</label>
                <div class="">
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    v-model="ad.floor"
                  />
                </div>
              </div>

              <div class="form-group col-sm-4">
                <label class="col-xl-6 col-lg-12 col-form-label">{{$t('Type')}}</label>
                <div class="">
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    v-model="ad.type"
                  />
                </div>
              </div>
            </div>

            <div class="col-xl-15">
              <template>
                <GmapMap
                  style="width: 100%; height: 30vh"
                  :center="{
                    lat: parseFloat(ad.lat),
                    lng: parseFloat(ad.long),
                  }"
                  :zoom="17"
                >
                  <GmapMarker
                    label="★"
                    :position="{
                      lat: parseFloat(ad.lat),
                      lng: parseFloat(ad.long),
                    }"
                  />
                </GmapMap>
              </template>
            </div>
          </div>
        </form>
        <!--end::Form-->
      </div>
    </div>
    <div v-else>
      <div class="card card-custom mt-5">
        <!--begin::Header-->
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">
              {{$t('Addresses Information')}}
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1"
              >{{$t('Check address informations')}}</span
            >
          </div>
          <div class="card-toolbar"></div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
          <div class="card-body">
            <!--begin::Heading-->

            <!--begin::Form Group-->

            <div class="row">
              <div class="form-group col-sm-6">
                <label class="col-xl-6 col-lg-12 col-form-label"
                  >{{$t('Street Name')}}</label
                >
                <div class="">
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    value="-"
                  />
                </div>
              </div>

              <div class="form-group col-sm-6">
                <label class="col-xl-6 col-lg-12 col-form-label">{{$t('Area')}}</label>
                <div class="">
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    value="-"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-4">
                <label class="col-xl-6 col-lg-12 col-form-label"
                  >{{$t('Building')}}</label
                >
                <div class="">
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    value="-"
                  />
                </div>
              </div>

              <div class="form-group col-sm-4">
                <label class="col-xl-6 col-lg-12 col-form-label">{{$t('Floor')}}</label>
                <div class="">
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    value="-"
                  />
                </div>
              </div>

              <div class="form-group col-sm-4">
                <label class="col-xl-6 col-lg-12 col-form-label">{{$t('Type')}}</label>
                <div class="">
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    value="-"
                  />
                </div>
              </div>
            </div>

            <!-- <div class="col-xl-15">
                          <template>
                            <GmapMap
                              style="width: 100%; height: 30vh"
                              :center="{
                                lat: 0,
                                lng:  0,
                              }"
                              :zoom="17"
                            >
                              <GmapMarker
                                label="★"
                                :position="{
                                  lat: 0,
                                lng: 0,
                                }"
                              />
                            </GmapMap>
                          </template>
                        </div> -->
          </div>
        </form>
        <!--end::Form-->
      </div>
    </div>
  </div>

  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "AddressInformation",

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      IsthereAddreses: null,
    };
  },
  mounted() {
    if (this.customer.addresses.length === 0) {
      this.IsthereAddreses = false;
    } else {
      this.IsthereAddreses = true;
    }
  },
  methods: {
    save() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch("customers/update", this.customer);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        Swal.fire({
          title: "",
          text: "Driver has been update successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }, 2000);
    },
    cancel() {},
  },
};
</script>


<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>